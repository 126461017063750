import React from "react";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const Office = loadable(() => import("@components/Office/Office"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const OfficePage = ({ path }) => (
    <>
        <SEO
            title="Our Office"
            titleTemplate="Impressit"
            description="A place to work, communicate, and have fun together — take a look at the photos of our beautiful office in the historical building in Lviv city center."
            path={path}
        />
        <Layout path={path}>
            <Office />
        </Layout>
    </>
);

export default OfficePage;
